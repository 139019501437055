import axios from 'axios';

// 创建一个 Axios 实例
const req = axios.create({
    // baseURL: 'https://test.api.youganai.com:8088', // 设置默认 baseURL
    // baseURL: "https://console.api.youganai.com",
    baseURL:window.Glod.reqUrl,
});

// 请求拦截器
req.interceptors.request.use(
    (config) => {
        // 在请求发送之前对请求配置进行修改
        // 这里可以添加任何你想要的配置，例如 headers、params、等等


        const token = localStorage.getItem("edb-authorization-token")
        if (token && !config.url.endsWith("/login") && !config.url.endsWith("/captchaImage")) {
            config.headers["Authorization"] = token
            // "Bearer " +
        }
        return config;
    },
    (error) => {
        // 处理请求错误
        return Promise.reject(error);
    }
);

// 响应拦截器
req.interceptors.response.use(
    (response) => {
        // 在响应被接收之前对响应数据进行处理
        // 这里可以对响应数据进行修改，例如解析、转换等等
        return response;
    },
    (error) => {
        // 处理响应错误
        return Promise.reject(error);
    }
);

export default req;

