<template>
  <div class="home">
    <div class="box">
      <div class="content">
        <!--        <div class="broadcast">-->
        <!--          <img src="../assets/icons/speaker-icon.png" alt="" style="width: 18px;height: 18px;margin-left: 20px">-->
        <!--          <p style="margin-left: 10px">1111111111111111111111111111111111</p>-->
        <!--        </div>-->
        <div class="details">
          <div class="top">
            <div class="user">
              <div class="user-con">
                <div class="user-top">
                  <img src="../assets/icons/avatar-icon.png" alt="" style="width: 56px;height: 56px;">
                  <div class="user-text">
                    <p>尊敬的</p>
                    <p>{{ infoData.admin.nickname }}</p>
                  </div>
                </div>

                <div class="user-text2">
                  您好！我们致力于提供更智能的AI营销解决方案，希望您使用我们系统的每一天都工作轻松愉快....
                </div>
              </div>
            </div>

            <div class="account">
              <div class="box1">
                <div class="balance">
                  <img src="../assets/icons/balance-icon.png" alt="" style="width: 56px;height: 56px;margin-left: 29px">
                  <div style="display: flex;align-items: center;justify-content: space-between">
                    <p class="title">账户余额</p>
                    <p class="text">{{ infoData.balance }}</p>
                  </div>
                </div>
                <div class="balance">
                  <img src="../assets/icons/limit-icon.png" alt="" style="width: 56px;height: 56px;margin-left: 29px">
                  <div style="display: flex;align-items: center;justify-content: space-between">
                    <p class="title" style="color:#00A199;">授信额度</p>
                    <p class="text" style="color: #242424;">{{ infoData.quota }}</p>
                  </div>
                </div>
              </div>
              <div class="box2">
                <div class="box2-con">
                  <div class="box2-cc">
                    <div class="box2-tent">
                      <img src="../assets/icons/all-line.png" alt="" style="width: 44px;height: 44px;">
                      <div style="margin-left: 13px">
                        <p class="box2-title">{{ clubData.all_count }}</p>
                        <p class="box2-text">全部线索</p>
                      </div>
                    </div>
                    <div class="box2-tent">
                      <img src="../assets/icons/ling-line.png" alt="" style="width: 44px;height: 44px;">
                      <div style="margin-left: 13px">
                        <p class="box2-title">{{ clubData.allot_count }}</p>
                        <p class="box2-text">领取线索</p>
                      </div>
                    </div>
                    <div class="box2-tent">
                      <img src="../assets/icons/fen-line.png" alt="" style="width: 44px;height: 44px;">
                      <div style="margin-left: 13px">
                        <p class="box2-title">{{ clubData.receive_count }}</p>
                        <p class="box2-text">分配线索</p>
                      </div>
                    </div>
                    <div class="box2-tent">
                      <img src="../assets/icons/nodell-line.png" alt="" style="width: 44px;height: 44px;">
                      <div style="margin-left: 13px">
                        <p class="box2-title">{{ clubData.unhandled_count }}</p>
                        <p class="box2-text">未处理线索</p>
                      </div>
                    </div>
                  </div>


                </div>


              </div>
            </div>
            <div class="logs">
              <div class="logs-con">
                <div class="logs-top">
                  <img src="../assets/icons/logs-icon.png" alt="" style="width: 32px;height: 32px;">
                  <p class="logs-title">升级日志</p>
                </div>
                <div class="logs-box">
                  <div class="logs-text">
                    <p class="logs-lf">#升级日志#数字人 1.2.3版本 </p>
                    <p class="logs-rl">2023-09-28</p>
                  </div>
                </div>
              </div>
            </div>


          </div>
          <div class="centre">
            <div class="task">
              <div class="task-top">
                <img src="../assets/icons/task-icon.png" alt="" style="width: 32px;height: 32px;">
                <p class="task-title">外呼任务</p>
              </div>
              <div class="task-con">
                <div class="task-box1">
                  <div class="task-box1-top" style="width: 111px;height: 111px;">
                    {{ infoData.task_data.task_no_start }}
                  </div>
                  <div class="task-box1-text">
                    <div class="box1-tag"></div>
                    <div class="box1-text">未开始</div>
                  </div>
                </div>
                <div class="task-box1">
                  <div class="task-box1-top" style="background-color: #FFF0ED;border: none">
                    {{ infoData.task_data.task_stop }}
                  </div>
                  <div class="task-box1-text" style="background-color: #FFF0ED;">
                    <div>
                      <img src="../assets/icons/taskStop-icon.png" alt="" style="width: 16px;height: 16px;">
                    </div>
                    <div class="box1-text" style="color: #D54941;">暂停中</div>
                  </div>
                </div>


                <div class="task-box1">
                  <div class="task-box1-top" style="background-color: #E3F9E9;border: none">
                    {{ infoData.task_data.task_in }}
                  </div>
                  <div class="task-box1-text" style="background-color: #E3F9E9;">
                    <div>
                      <img src="../assets/icons/tasking-icon.png" alt="" style="width: 12px;height: 12px;">
                    </div>
                    <div class="box1-text" style="color: #2BA471;">进行中</div>
                  </div>
                </div>
                <div class="task-box1">
                  <div class="task-box1-top"
                       style="background-color: #F2F3FF;border: 4px solid #366EF4;width: 111px;height: 111px;">
                    {{ infoData.task_data.task_over }}
                  </div>
                  <div class="task-box1-text" style="background-color: #F2F3FF;">
                    <div style="width: 12px;height: 12px;background-color: #366EF4;border-radius: 50%">

                    </div>
                    <div class="box1-text" style="color: #366EF4;">已完成</div>
                  </div>
                </div>

                <div class="task-box1">
                  <div class="task-box1-top" style="background-color: #DCDCDC;border: none;">
                    {{ infoData.task_data.task_end }}
                  </div>
                  <div class="task-box1-text" style="background-color: #DCDCDC;">
                    <div style="width: 14px;height: 14px;background-color: #535D6D;">

                    </div>
                    <div class="box1-text" style="color: #535D6D;">已终止</div>
                  </div>
                </div>

              </div>
            </div>
            <div class="robot">
              <div class="robot-top">
                <img src="../assets/icons/rbt-icon.png" alt="" style="width: 32px;height: 32px;">
                <p class="robot-title">机器人</p>
              </div>
              <div class="robot-con">
                <div class="robot-box">
                  <img src="../assets/icons/rbt1-icon.png" alt="" style="width: 75px;height: 75px;">
                  <p class="robot-box-text">空闲中</p>
                  <p class="robot-box-text2"> {{ infoData.task_data.robot_free }}</p>
                </div>
                <div class="robot-box">
                  <img src="../assets/icons/rbt2-icon.png" alt="" style="width: 75px;height: 75px;">
                  <p class="robot-box-text" style="color: #366EF4;">拨打中</p>
                  <p class="robot-box-text2">{{ infoData.task_data.robot_way }}</p>
                </div>
                <div class="robot-box">
                  <img src="../assets/icons/rbt3-icon.png" alt="" style="width: 75px;height: 75px;">
                  <p class="robot-box-text" style="color: #535D6D;">占用中</p>
                  <p class="robot-box-text2">{{ infoData.task_data.robot_stop }}</p>
                </div>
              </div>
            </div>
            <div class="line">
              <div class="line-top">
                <img src="../assets/icons/line-icon.png" alt="" style="width: 32px;height: 32px;">
                <p class="line-title">线路</p>
              </div>
              <div class="lin-con">
                <div class="lin-box1">
                  <div class="lin-box1-con">
                    <p>空闲中</p>
                    <p style="font-weight: 500">{{ infoData.task_data.line_free }}</p>
                  </div>
                </div>

                <div class="lin-box1" style="background-color: #D7E2FD;border: 1px solid #366EF4;">
                  <div class="lin-box1-con">
                    <p>拨打中</p>
                    <p style="font-weight: 500">{{ infoData.task_data.line_way }}</p>
                  </div>
                </div>

                <div class="lin-box1" style="background-color: #DDDFE2;border: 1px solid #323843;">
                  <div class="lin-box1-con">
                    <p>占用中</p>
                    <p style="font-weight: 500">{{ infoData.task_data.line_stop }}</p>
                  </div>
                </div>


              </div>
            </div>
          </div>

          <div class="foot">
            <div style="width: 100%;height: 31px;"></div>
            <div class="foot-top">
              <img src="../assets/icons/num-icon.png" alt="" style="width: 32px;height: 32px;">
              <p class="foot-title">实时数据</p>
            </div>
            <div class="filtrate">
              <div class="fil-box" v-for="(item ,index) in fileList" :key="index" @click="check(item,index)"
                   :class="dayAct==index? 'boxAct':''">{{ item.name }}
              </div>
              <!--                <div class="fil-box">昨日</div>-->
              <!--                <div class="fil-box">近七天</div>-->
              <!--                <div class="fil-box">近30天</div>-->
              <!--                <div class="fil-box">半年</div>-->
              <!--                <div class="fil-box">一年</div>-->
              <el-date-picker
                  v-model="date"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy-MM-dd"
                  @change="changeDate"
                  style="height: 32px;">
              </el-date-picker>
            </div>
            <div class="foot-con">
              <div class="foot-box">
                <div class="num-box">
                  <p class="num-box-title">客户总数</p>
                  <p class="num-box-text"> {{ realtime.all_customer_count }}</p>
                </div>

                <div class="num-box">
                  <p class="num-box-title">新增客户数</p>
                  <p class="num-box-text"> {{ realtime.new_customer_count }}</p>
                </div>
                <div class="num-box">
                  <p class="num-box-title">线索领取数</p>
                  <p class="num-box-text"> {{ realtime.receive_clue_count }}</p>
                </div>

                <div class="num-box">
                  <p class="num-box-title">线索消耗</p>
                  <p class="num-box-text"> {{ realtime.clue_expense_amount }}</p>
                </div>
                <div class="num-box">
                  <p class="num-box-title">话费消耗</p>
                  <p class="num-box-text"> {{ realtime.call_expense_amount }}</p>
                </div>
                <div class="num-box">
                  <p class="num-box-title">已呼客户数</p>
                  <p class="num-box-text"> {{ realtime.has_call_count }}</p>
                </div>

                <div class="num-box">
                  <p class="num-box-title">未呼客户数</p>
                  <p class="num-box-text"> {{ realtime.no_call_count }}</p>
                </div>

                <div class="num-box">
                  <p class="num-box-title">接通客户数</p>
                  <p class="num-box-text"> {{ realtime.connect_call_count }}</p>
                </div>

                <div class="num-box">
                  <p class="num-box-title">接通率</p>
                  <p class="num-box-text"> {{ realtime.connect_rate }}</p>
                </div>

                <div class="num-box">
                  <p class="num-box-title">通话总时长（分）</p>
                  <p class="num-box-text"> {{ realtime.call_total_time }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>
  </div>
</template>

<script>
import {indexInfo, personInfo, homeInfo, realtimeData} from '../request/api'

export default {
  name: 'HomeView',
  data() {

    return {
      date: [],
      dayAct: 0,
      dayTime: '',
      fileList: [
        {
          id: 1,
          name: '今日'
        },
        {
          id: -1,
          name: '昨日'
        },
        {
          id: 7,
          name: '近七天'
        },
        {
          id: 30,
          name: '近30天'
        },
        {
          id: 182,
          name: '半年'
        },
        {
          id: 365,
          name: '一年'
        },
      ],
      infoData: {},
      sdate: '',
      edate: '',
      clubData: {},
      realtime: {}
    }
  },
  created() {
    this.getInfo()
    this.getClubInfo()
    this.getRealtimeData()
  },

  methods: {
    getRealtimeData() {
      realtimeData({
        sdate: this.sdate,
        edate: this.edate
      }).then(res => {
        if (res.data.code == 0) {
             this.realtime=res.data.data
        }
      })
    },
    changeDate() {
      console.log(this.date)
      this.sdate = this.date[0]
      this.edate = this.date[1]
      this.getRealtimeData()
    },
    getInfo() {
      indexInfo({
        sdate: this.sdate,
        edate: this.edate
      }).then(res => {
        if (res.code == 200) {
          this.infoData = res.data
        }
      })
    },
    getClubInfo() {
      homeInfo().then(res => {
        if (res.data.code == 0) {
          this.clubData = res.data.data
        }
      })
    },
    check(item, index) {
      this.dayAct = index
// 获取当前日期
      var currentDate = new Date();

// 获取前一天的日期
      var previousDate = new Date(currentDate);
      previousDate.setDate(currentDate.getDate() - 1);

      // 获取前七天的日期
      var sevenDaysAgo = new Date(currentDate);
      sevenDaysAgo.setDate(currentDate.getDate() - 7);

      // 获取当前日期的前30天的日期
      var thirtyDaysAgo = new Date(currentDate);
      thirtyDaysAgo.setDate(currentDate.getDate() - 30);

      // 获取当前日期的前半年的日期
      var sixMonthsAgo = new Date(currentDate);
      sixMonthsAgo.setMonth(currentDate.getMonth() - 6);

      // 获取当前日期的前一年的日期
      var oneYearAgo = new Date(currentDate);
      oneYearAgo.setFullYear(currentDate.getFullYear() - 1);


// 提取当前日期的年、月、日
      var currentYear = currentDate.getFullYear();
      var currentMonth = (currentDate.getMonth() + 1).toString().padStart(2, '0');
      var currentDay = currentDate.getDate().toString().padStart(2, '0');

// 提取前一天日期的年、月、日
      var previousYear = previousDate.getFullYear();
      var previousMonth = (previousDate.getMonth() + 1).toString().padStart(2, '0');
      var previousDay = previousDate.getDate().toString().padStart(2, '0');

      // 提取前七天日期的年、月、日
      var sevenDaysAgoYear = sevenDaysAgo.getFullYear();
      var sevenDaysAgoMonth = (sevenDaysAgo.getMonth() + 1).toString().padStart(2, '0');
      var sevenDaysAgoDay = sevenDaysAgo.getDate().toString().padStart(2, '0');

// 提取30天前日期的年、月、日
      var thirtyDaysAgoYear = thirtyDaysAgo.getFullYear();
      var thirtyDaysAgoMonth = (thirtyDaysAgo.getMonth() + 1).toString().padStart(2, '0');
      var thirtyDaysAgoDay = thirtyDaysAgo.getDate().toString().padStart(2, '0');
// 提取前半年日期的年、月、日
      var sixMonthsAgoYear = sixMonthsAgo.getFullYear();
      var sixMonthsAgoMonth = (sixMonthsAgo.getMonth() + 1).toString().padStart(2, '0');
      var sixMonthsAgoDay = sixMonthsAgo.getDate().toString().padStart(2, '0');

      // 提取前一年日期的年、月、日
      var oneYearAgoYear = oneYearAgo.getFullYear();
      var oneYearAgoMonth = (oneYearAgo.getMonth() + 1).toString().padStart(2, '0');
      var oneYearAgoDay = oneYearAgo.getDate().toString().padStart(2, '0');


// 构建日期字符串
      var currentDateStr = `${currentYear}-${currentMonth}-${currentDay}`;
      var previousDateStr = `${previousYear}-${previousMonth}-${previousDay}`;
      var sevenDaysAgoStr = `${sevenDaysAgoYear}-${sevenDaysAgoMonth}-${sevenDaysAgoDay}`;
      var thirtyDaysAgoStr = `${thirtyDaysAgoYear}-${thirtyDaysAgoMonth}-${thirtyDaysAgoDay}`;
      var sixMonthsAgoStr = `${sixMonthsAgoYear}-${sixMonthsAgoMonth}-${sixMonthsAgoDay}`;
      var oneYearAgoStr = `${oneYearAgoYear}-${oneYearAgoMonth}-${oneYearAgoDay}`;


      if (index == 0) {
        this.sdate = currentDateStr
        this.edate = currentDateStr
        this.getRealtimeData()
        return
      }
      if (index == 1) {
        this.sdate = previousDateStr
        this.edate = previousDateStr
        this.getRealtimeData()
        return
      }
      if (index == 2) {
        this.sdate = sevenDaysAgoStr
        this.edate = currentDateStr
        this.getRealtimeData()
        return
      }
      if (index == 3) {
        this.sdate = thirtyDaysAgoStr
        this.edate = currentDateStr
        this.getRealtimeData()
        return
      }
      if (index == 4) {
        this.edate = currentDateStr
        this.sdate = sixMonthsAgoStr
        this.getRealtimeData()
        return
      }
      if (index == 5) {
        this.edate = currentDateStr
        this.sdate = oneYearAgoStr
        this.getRealtimeData()
        return
      }


// 输出前一天的日期字符串
      console.log(previousDateString);


    }
  }
}
</script>
<style lang="less" scoped>

.home {
  width: 100%;
  height: 1180px;
  background-color: #F3F3F3;
  position: relative;

  .box {
    position: absolute;
    top: 21px;
    left: 21px;
    right: 21px;

    .content {
      width: 100%;
      height: 100%;

      .broadcast {
        display: flex;
        align-items: center;
        width: 100%;
        height: 22px;
        background: #FFFCEA;
        border-radius: 4px 4px 4px 4px;
        font-size: 11px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: #FF0E00;
        line-height: 22px;
      }

      .details {
        //margin-top: 44px;
        width: 100%;

        .top {
          width: 100%;
          height: 244px;
          display: flex;

          .user {
            display: flex;
            justify-content: center;
            width: 309px;
            height: 244px;
            background: #FFFFFF;
            border-radius: 6px;

            .user-con {
              width: 255px;

              .user-top {
                margin-top: 30px;
                display: flex;
                height: 56px;
                align-items: center;

                .user-text {
                  margin-left: 14px;
                  height: 44px;
                  font-size: 16px;
                  font-family: PingFang SC, PingFang SC;
                  font-weight: 400;
                  color: #242424;
                  line-height: 22px;

                }
              }

              .user-text2 {
                margin-top: 29px;
                margin-left: 13px;
                //width: 242px;
                height: 78px;
                font-size: 14px;
                font-family: PingFang SC, PingFang SC;
                font-weight: 400;
                color: #535D6D;
                line-height: 26px;
              }
            }
          }

          .account {
            margin-left: 22px;
            width: 706px;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .box1 {
              width: 706px;
              height: 115px;
              background: #FFFFFF;
              box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
              border-radius: 6px;
              opacity: 1;
              display: flex;

              .balance {
                width: 50%;
                height: 100%;
                display: flex;
                align-items: center;

                .title {
                  margin-left: 11px;
                  height: 24px;
                  font-size: 16px;
                  font-family: Source Han Sans SC, Source Han Sans SC;
                  font-weight: 500;
                  color: #F46808;
                  line-height: 24px;
                }

                .text {
                  margin-left: 20px;
                  height: 24px;
                  font-size: 30px;
                  font-family: Source Han Sans SC, Source Han Sans SC;
                  font-weight: 500;
                  color: #FF0E00;
                  line-height: 24px;

                }
              }
            }

            .box2 {
              width: 706px;
              height: 115px;
              background: #FFFFFF;
              box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
              border-radius: 6px;
              opacity: 1;
              position: relative;

              .box2-con {
                position: absolute;
                left: 35px;
                right: 35px;
                height: 100%;

                .box2-cc {
                  width: 100%;
                  height: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;

                  .box2-tent {
                    display: flex;
                    height: 44px;

                    .box2-title {
                      height: 22px;
                      font-size: 24px;
                      font-family: Source Han Sans SC, Source Han Sans SC;
                      font-weight: 500;
                      color: #242424;
                      line-height: 22px;
                    }

                    .box2-text {
                      height: 22px;
                      font-size: 14px;
                      font-family: Source Han Sans SC, Source Han Sans SC;
                      font-weight: 400;
                      color: #242424;
                      line-height: 22px;
                    }
                  }
                }
              }
            }
          }

          .logs {
            display: flex;
            justify-content: center;

            margin-left: 15px;
            width: 592px;
            height: 100%;
            background: #FFFFFF;
            box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
            border-radius: 6px;

            .logs-con {
              width: 530px;
              height: 100%;

              .logs-top {
                margin-top: 22px;
                display: flex;
                align-items: center;
                height: 32px;

                .logs-title {
                  margin-left: 14px;
                  font-size: 20px;
                  font-family: Source Han Sans SC, Source Han Sans SC;
                  font-weight: 500;
                  color: #242424;
                }
              }

              .logs-box {
                margin: 35px 0 0 7px;
                //width: 100%;
                .logs-text {
                  display: flex;
                  justify-content: space-between;
                  border-bottom: 1px dashed #DCDCDC;

                  .logs-lf {

                    height: 32px;
                    font-size: 16px;
                    font-family: PingFang SC, PingFang SC;
                    font-weight: 400;
                    color: #242424;
                    line-height: 32px;
                  }

                  .logs-rl {
                    height: 32px;
                    font-size: 14px;
                    font-family: PingFang SC, PingFang SC;
                    font-weight: 400;
                    color: #C5C5C5;
                    line-height: 32px;
                  }
                }
              }
            }
          }
        }

        .centre {
          margin-top: 19px;
          width: 100%;
          height: 298px;
          display: flex;

          .task {
            width: 773px;
            height: 100%;
            background: #FFFFFF;
            box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
            border-radius: 6px;

            .task-top {
              margin: 21px 0 0 15px;
              display: flex;
              align-items: center;
              height: 32px;

              .task-title {
                margin-left: 14px;
                height: 28px;
                font-size: 20px;
                font-family: Source Han Sans SC, Source Han Sans SC;
                font-weight: 500;
                color: #242424;
              }
            }

            .task-con {
              margin-top: 44px;
              width: 100%;
              display: flex;
              justify-content: space-around;

              .task-box1 {
                width: 119px;
                display: flex;
                flex-direction: column;
                align-items: center;

                .task-box1-top {
                  width: 119px;
                  height: 119px;
                  border-radius: 50%;
                  opacity: 1;
                  border: 4px solid #E7E8EB;
                  font-size: 32px;
                  font-family: Source Han Sans CN, Source Han Sans CN;
                  font-weight: bold;
                  color: #242424;
                  line-height: 119px;
                  text-align: center;
                }

                .task-box1-text {
                  margin-top: 16px;
                  width: 97px;
                  height: 34px;
                  background: #E7E8EB;
                  border-radius: 100px;
                  display: flex;
                  justify-content: center;
                  align-items: center;

                  .box1-tag {
                    width: 12px;
                    height: 3px;
                    background: #242424;
                  }

                  .box1-text {
                    margin-left: 5px;
                    font-size: 14px;
                    font-family: PingFang SC, PingFang SC;
                    font-weight: 400;
                    color: #242424;
                  }
                }
              }
            }
          }

          .robot {
            margin-left: 12px;
            width: 453px;
            height: 298px;
            background: #FFFFFF;
            box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
            border-radius: 6px;

            .robot-top {
              margin: 21px 0 0 40px;
              height: 32px;
              display: flex;
              align-items: center;

              .robot-title {
                margin-left: 14px;
                height: 28px;
                font-size: 20px;
                font-family: Source Han Sans SC, Source Han Sans SC;
                font-weight: 500;
                color: #242424;

              }
            }

            .robot-con {
              margin-top: 44px;
              display: flex;
              justify-content: space-around;

              .robot-box {
                width: 75px;
                display: flex;
                flex-direction: column;
                align-items: center;

                .robot-box-text {
                  margin-top: 5px;
                  height: 22px;
                  font-size: 16px;
                  font-family: PingFang SC, PingFang SC;
                  font-weight: 400;
                  color: #FA9550;
                  line-height: 22px;

                }

                .robot-box-text2 {
                  margin-top: 21px;
                  font-size: 20px;
                  font-family: Source Han Sans SC, Source Han Sans SC;
                  font-weight: 500;
                  color: #242424;
                }
              }
            }
          }

          .line {
            margin-left: 12px;
            width: 390px;
            height: 298px;
            background: #FFFFFF;
            box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
            border-radius: 6px;

            .line-top {
              margin: 21px 0 0 29px;
              display: flex;
              align-items: center;

              .line-title {
                margin-left: 14px;
                height: 28px;
                font-size: 20px;
                font-family: Source Han Sans SC, Source Han Sans SC;
                font-weight: 500;
                color: #242424;
              }
            }

            .lin-con {
              margin-top: 27px;
              width: 100%;
              display: flex;
              flex-direction: column;
              align-items: center;

              .lin-box1 {
                margin-bottom: 23px;
                border: 1px solid #FA9550;
                width: 298px;
                height: 46px;
                background: #FEEADC;
                border-radius: 6px;
                display: flex;
                justify-content: center;

                .lin-box1-con {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  width: 248px;
                  font-size: 16px;
                  font-family: Source Han Sans SC, Source Han Sans SC;
                  font-weight: 400;
                  color: #242424;
                }
              }
            }
          }
        }

        .foot {
          position: relative;
          margin-top: 19px;
          width: 100%;
          height: 490px;
          background: #FFFFFF;
          box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
          border-radius: 6px;

          .foot-top {
            margin-left: 35px;
            display: flex;
            align-items: center;
            height: 32px;

            .foot-title {
              margin-left: 14px;
              font-size: 20px;
              font-family: Source Han Sans SC, Source Han Sans SC;
              font-weight: 500;
              color: rgba(0, 0, 0, 0.9);

            }
          }

          .filtrate {
            position: absolute;
            top: 36px;
            right: 41px;
            display: flex;

            .fil-box {
              width: 60px;
              height: 32px;
              background: #FFFFFF;
              border-radius: 4px;
              border: 1px solid #366EF4;
              font-size: 14px;
              font-family: Source Han Sans SC, Source Han Sans SC;
              font-weight: 400;
              color: #366EF4;
              text-align: center;
              line-height: 32px;
              margin-right: 12px;
              cursor: pointer;
            }

            .boxAct {
              background: #366EF4;
              color: #fff
            }
          }

          .foot-con {
            position: absolute;
            top: 104px;
            left: 49px;
            right: 40px;

            .foot-box {
              width: 100%;
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;

              .num-box {
                margin-bottom: 24px;
                width: 285px;
                height: 162px;
                background: #F2F3FF;
                border-radius: 6px 6px 6px 6px;
                opacity: 1;
                border: 1px solid #366EF4;

                .num-box-title {
                  margin: 48px 0 0 32px;
                  height: 22px;
                  font-size: 16px;
                  font-family: Source Han Sans SC, Source Han Sans SC;
                  font-weight: 400;
                  color: #002A7C;
                  line-height: 22px;

                }

                .num-box-text {
                  margin: 8px 0 0 32px;
                  height: 36px;
                  font-size: 28px;
                  font-family: Source Han Sans SC, Source Han Sans SC;
                  font-weight: 500;
                  color: #242424;
                  line-height: 36px;
                }
              }
            }
          }
        }
      }
    }
  }
}


</style>
