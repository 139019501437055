import Vue from 'vue'
// 清除默认样式
import "reset-css"

import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
import "@/assets/icons/index"
import "@/utils/filters"
// 将自动注册所有组件为全局组件
import dataV from '@jiaminghi/data-view'
import "./plugins/vcharts"
// import { myMessage } from './utils/resetMessage'
// import VueECharts from 'vue-echarts'
//
// // 引入 ECharts 主模块
// import echarts from 'echarts'
//
// // 注册 Vue-ECharts 组件
// Vue.component('v-chart', VueECharts)
//
// // 注册 ECharts 主模块
// Vue.prototype.$echarts = echarts


import './utils/rem.js'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(ElementUI);
// Vue.prototype.$message = myMessage
Vue.use(dataV)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
