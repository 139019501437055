import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import store from "@/store"
import { getMenu} from "@/request/api"

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'mainlayout',
        component: () => import(/* webpackChunkName: "mainlayout" */ '../views/layout/MainLayout.vue'),
        redirect: "/home",
        children: [
            {
                title: "新增外呼任务",
                path: "/business/newTest",
                name: 'newTest',
                component: () => import(/* webpackChunkName: "customer" */ '../views/business/newTest.vue')
            },
            {
                path: "/business/newTactics",
                name: 'newTactics',
                component: () => import(/* webpackChunkName: "customer" */ '../views/business/newTactics.vue')
            },
            {
                path: "/Crm/companyDetail",
                name: 'companyDetail',
                component: () => import(/* webpackChunkName: "customer" */ '../views/Crm/companyDetail.vue')
            },
            // {
            //   path:"/home",
            //   component: () => import(/* webpackChunkName: "home" */ '../views/HomeView.vue')
            // },
            // {
            //   path:"/customer/customer",
            //   component: () => import(/* webpackChunkName: "customer" */ '../views/customer/Customer.vue')
            // },
            // {
            //   path:"/customer/visit",
            //   component: () => import(/* webpackChunkName: "visit" */ '../views/customer/Visit.vue')
            // },
            // {
            //   path:"/flow/definition",
            //   component: () => import(/* webpackChunkName: "definition" */ '../views/flow/Definition.vue'),
            //    meta:{titles:["客户管理", "拜访记录"]}
            // }
        ]

    },

    {
        path: '/about',
        name: 'about',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
        meta: {
            title: []
        }
    },
    // {
    //   path: '/login',
    //   name: 'login',
    //   component: () => import(/* webpackChunkName: "login" */ '../views/login/Login.vue')
    // },
    {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "login" */ '../views/login/LonIn')
    },
    {
        path: '*',
        component: () => import(/* webpackChunkName: "404" */ '../views/404.vue')
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach(async (to, from, next) => {
    // 【重点】：只有next()是放行， 里面传了路径就变成跳转到，跳转到就会重新执行守卫的代码(这个函数)
    const token = localStorage.getItem("edb-authorization-token")
    // 管理系统常见的两个经典的逻辑：
    // 1、如果用户访问登录页面， 但是token已经存在， 跳转到首页
    if (to.path === "/login" && token) {
        next("/");  // 跳转到
        return
    }
    // 2、如果用户访问不是登录页面，但是没有token，跳转到登录页面
    if (to.path !== "/login" && !token) {
        next("/login")
        return
    }

    // if(token && vuex中menuData为0){
    if (token && store.state.userMenuData.menuData.length == 0) {
        // 获取用户的菜单数据
        let GetUserRoutersApiRes = await getMenu();
        if (!GetUserRoutersApiRes) return;
        let newUserMenuData = [{title: "首页", path: "home", icon: "dashboard",}]
        let ret = GetUserRoutersApiRes.data.map(item => {
            if (item.child) {
                return {
                    title: item.name,
                    icon: item.icon,
                    default_icon: item.default_icon,
                    path: item.url,
                    children: item.child.map(sitem => {
                        return {
                            title: sitem.name,
                            path: item.url + "/" + sitem.url
                        }
                    })
                }
            } else {
                return {
                    title: item.name,
                    path: item.url,
                    icon: item.icon,
                    default_icon: item.default_icon,
                }
            }

        })
        newUserMenuData = [...newUserMenuData, ...ret];
        console.log('newUserMenuData',newUserMenuData)
        store.commit("userMenuData/changeMenuData", newUserMenuData)
        // 以上----生成菜单数据
        // 以下----生成用户可以访问的路由数据
        let newChildrenRoutes = [{
            path: "/home",
            meta: {titles: ["首页"]},
            component: () => import('../views/HomeView.vue')
        }, {
            path: "/profile",
            meta: {titles: ["个人中心"]},
            component: () => import('../views/Profile.vue')
        }
        ]
        //动态路由注册
        GetUserRoutersApiRes.data.forEach(item => {
            // if (item.children) {
                let ret = item.child.map(sitem => {
                    return {
                        path: item.url + "/" + sitem.url,
                        component: () => import(`../views/${item.url}/${sitem.url}.vue`),
                        meta: {
                            titles: [item.name, sitem.name]
                        }
                    }
                })
            // }


            newChildrenRoutes = [...newChildrenRoutes, ...ret]

        });

        // console.log('newChildrenRoutes',newChildrenRoutes);

        // 要把这个生成好的数组添加到mainlayout路由里面的children，做为子路由
        // router.addRoute(父路由名称，单个子路由对象);
        newChildrenRoutes.forEach(item => {
            router.addRoute("mainlayout", item);
        });

        // 这个to.path要写.
        // 如果直接next()，路由还没有完整，还是个空的。它不确定路由里面有没有这个路径。
        // 加了to.path之后，会重新走一遍路由守卫，此时路由添加完毕，可以检查出用户能不能访问这个路径
        next(to.path);
        return

    }


    next()  // 放行
})
// router.beforeEach((to,from,next)=>{

//   const token = localStorage.getItem("edb-authorization-token")
//   // 管理系统常见的两个经典的逻辑：
//   // 1、如果用户访问登录页面， 但是token已经存在， 跳转到首页
//   if(to.path==="/login" && token){
//     next("/");

//   }else
//   // 2、如果用户访问不是登录页面，但是没有token，跳转到登录页面
//   if(to.path!=="/login" && !token){
//     next("/login")

//   }else{
//     next()
//   }
// })


const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function (location) {
    return originalPush.call(this, location).catch(err => {
    })
};
export default router
