class ZoomRatio {
    constructor() {
        // this.flag = false;
    }

    // 监听方法兼容写法
    _addHandler(element, type, handler) {
        if (element.addEventListener) {
            element.addEventListener(type, handler, false);
        } else if (element.attachEvent) {
            element.attachEvent('on' + type, handler);
        } else {
            element['on' + type] = handler;
        }
    }
    // 校正浏览器缩放比例
    _correct() {
        let devicewidth = document.documentElement.clientWidth//获取当前分辨率下的可是区域宽度
        // let devicewidth = window.screen.width;//获取当前分辨率下的可是区域宽度
        let scale = devicewidth / 1920 // 分母——设计稿的尺寸
        document.getElementsByTagName('body')[0].style.zoom = scale//放大缩小相应倍数
    }
    // 监听页面缩放
    _watch() {
        let t = this;
        t._addHandler(window, 'resize', function() { // 注意这个方法是解决全局有两个window.resize
            // 重新校正
            t._correct()
        })
    }
    // 初始化页面比例
    init() {
        let t = this;
        // 初始化页面校正浏览器缩放比例
        t._correct();
        // 开启监听页面缩放
        t._watch();
    }
}
export default ZoomRatio;
// class ZoomRatio {
//     constructor() {
//         // this.flag = false;
//     }
//
//     // 监听方法兼容写法
//     _addHandler(element, type, handler) {
//         if (element.addEventListener) {
//             element.addEventListener(type, handler, false);
//         } else if (element.attachEvent) {
//             element.attachEvent('on' + type, handler);
//         } else {
//             element['on' + type] = handler;
//         }
//     }
//
//     // 校正浏览器缩放比例
//     _correct() {
//         // let devicewidth = document.documentElement.clientWidth; // 获取当前分辨率下的可是区域宽度
//         let devicewidth = window.screen.width; // 获取当前分辨率下的可是区域宽度
//         console.log('展示宽度',devicewidth)
//         let scale = devicewidth / 1920; // 分母——设计稿的尺寸
//         document.getElementsByTagName('body')[0].style.zoom = scale; // 放大缩小相应倍数
//     }
//
//     // 监听页面缩放
//     _watch() {
//         let t = this;
//         t._addHandler(window, 'resize', function() {
//             // 重新校正
//             t._correct();
//         });
//
//         // 添加对鼠标滚轮事件的监听
//         t._addHandler(window, 'wheel', function() {
//             console.log('鼠标')
//             t._correct();
//         });
//     }
//
//     // 初始化页面比例
//     init() {
//         let t = this;
//         // 初始化页面校正浏览器缩放比例
//         t._correct();
//         // 开启监听页面缩放
//         t._watch();
//     }
// }
//
// export default ZoomRatio;

