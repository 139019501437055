<template>
  <div id="app">
      <router-view></router-view>
  </div>
</template>

<script>
import ZoomRatio from './utils/zoom'
// import DevicePixelRatio from'./utils/devicePixelRatio'
// import autofit from 'autofit.js'
export default {
  name: 'app',
  components: {

  },
  data(){
    return{

    }
  },
  created() {
    new ZoomRatio().init()
// 禁止缩放
//     window.addEventListener(
//         "mousewheel",
//         function (event) {
//           if (event.ctrlKey === true || event.metaKey) {
//             event.preventDefault();
//           }
//         },
//         { passive: false }
//     );
    //firefox
    // window.addEventListener(
    //     "DOMMouseScroll",
    //     function (event) {
    //       if (event.ctrlKey === true || event.metaKey) {
    //         event.preventDefault();
    //       }
    //     },
    //     { passive: false }
    // );
  },
  mounted() {


    // window.onresize = () => {
    //   return (() => {
    //     // 页面变化监听尺寸
    //     this.bodyScale()
    //   })()
    // }

  },
  methods:{
    // 获取当前屏幕尺寸函数
    bodyScale(){
      let devicewidth = document.documentElement.clientWidth//获取当前分辨率下的可是区域宽度
      let scale = devicewidth / 1920 // 分母——设计稿的尺寸
      document.body.style.zoom = scale//放大缩小相应倍数
    }
  }

}
</script>

<style>
html,body,#app {
  /*width: 100vw;*/
  /*height: 100vh;*/
  width: 100%;
  height: 100%;
  background-color: #fff;
  font-family:Source Han Sans SC-Normal, Source Han Sans SC;
  overflow-x: hidden;
  color: #242424;
  font-size: 16px;
  /* font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*color: #2c3e50; *!*/
}
</style>
