import instance from "./request"
import req    from "./req"
// 获取验证码请求
export const GetCaptchaCodeApi = () => instance.get("/prod-api/captchaImage");

// 登录请求
export const LoginApi = (params) => instance.post("/prod-api/login", params);



// 获取用户可以访问的路由的api
export const GetUserRoutersApi = () => instance.get("/prod-api/getRouters");

// 获取用户信息
export const GetUserInfoApi = () => instance.get("/prod-api/getInfo");

// 客户管理--客户列表展示
export const GetCustomerListApi = (params) => instance.get("/prod-api/customer", {params});

// 导出表格接口
export const CustomerExportApi = (params,configs) => instance.post("/prod-api/customer/export", params,configs);




// 审核流程定义的接口
export const BpmnInfoApi = (params,configs) => instance.post("/prod-api/business/bpmnInfo", params,configs);










// 登录请求
export const Login = (params) => instance.post("/admin/Login/login", params);
// 获取用户可以访问的路由的api
export const getMenu = () => instance.post("/admin/Menu/getMenu");
//获取创建新增参数外呼任务
export const getConfig = () => instance.post("/admin/AiCall.Task/getConfig");

//获取流程图节点
// export const getToneBranch = (params) => instance.post("/admin/AiCall.CallGather/getToneBranch",params);



//新增话术
export const saveGather = (params) => instance.post("/admin/AiCall.CallGather/saveGather",params);

//获取话术列表
export const getAiCallList = (params) => instance.post("/admin/AiCall.CallGather/list",params);

//全部话术列表

export const allCallList = () => instance.post("/admin/AiCall.CallGather/all");

export const allCallListPro = (params) => instance.post("/admin/AiCall.CallGather/all",params);




//获取流程列表
export const getToneBranch = (params) => instance.post("/admin/AiCall.CallGather/getToneBranch",params);




//流程列表增改分支
export const saveToneBranch = (params) => instance.post("/admin/AiCall.CallGather/saveToneBranch",params);

//语境

export const branchList = (params) => instance.post("/admin/AiCall.CallGather/branchList",params);

//新增语境
export const saveBranch = (params) => instance.post("/admin/AiCall.CallGather/saveBranch",params);

//删除语境
export const delBranch = (params) => instance.post("/admin/AiCall.CallGather/delBranch",params);


//录音文件列表

export const recordList = (params) => instance.post("/admin/AiCall.CallGather/recordList",params);
//全部录音列表
export const recordAll = () => instance.post("/admin/AiCall.CallGather/recordAll");
export const recordIDAll = (params) => instance.post("/admin/AiCall.CallGather/recordAll",params);
//增改录音
export const addVoice = (params) => instance.post("/admin/AiCall.CallGather/saveRecord",params);

//用于选择的黑名单组
export const allGroup = () => instance.post("/admin/AiCall.BlackList/allGroup",);


//黑名单组

export const blackGroup = (params) => instance.post("/admin/AiCall.BlackList/groupList",params);


//新增黑名单组

export const addBlackGroup = (params) => instance.post("/admin/AiCall.BlackList/saveGroup",params);

//删除黑名单组

export const delGroups = (params) => instance.post("/admin/AiCall.BlackList/delGroup",params);

// 黑名单组表格

export const getBlackCustomerList = (params) => instance.post("/admin/AiCall.BlackList/getBlackCustomerList",params);

//黑名单用户编辑

export const updateCustomer = (params) => instance.post("/admin/AiCall.BlackList/updateCustomer",params);


//用户移除黑名单

export const outBlackList = (params) => instance.post("/admin/AiCall.BlackList/outBlackList",params);

//黑名单单用户导入

export const importOneCustomer = (params) => instance.post("/admin/AiCall.BlackList/importOneCustomer",params);
//用户转移黑名单分组
export const removeCustomer = (params) => instance.post("/admin/AiCall.BlackList/removeCustomer",params);
//复制分组

export const copyCustomer = (params) => instance.post("/admin/AiCall.BlackList/copyCustomer",params);

//黑名单历史
export const historyList = (params) => instance.post("/admin/AiCall.BlackList/historyList",params);


//风控列表

export const riskControl = (params) => instance.post("/admin/AiCall.RiskControl/list",params);

//新增,修改风控

export const riskControlSave = (params) => instance.post("/admin/AiCall.RiskControl/save",params);
//删除风控策略


export const delControlSave = (params) => instance.post("/admin/AiCall.RiskControl/del",params);
//全部风控列表

export const allControl = () => instance.post("/admin/AiCall.RiskControl/all");

//外呼任务列表


export const taskList = (params) => instance.post("/admin/AiCall.Task/list",params);


//新增外呼任务

export const saveTask = (params) => instance.post("/admin/AiCall.Task/save",params);


//呼叫统计
export const callStatistics = (params) => instance.post("/admin/AiCall.Task/callStatistics",params);

//呼叫列表

export const callList = (params) => instance.post("/admin/AiCall.Task/callList",params);


//导入客户

export const  importPer= (params) => instance.post("/admin/Crm.Customer/import",params);

//移除客户

export const  delCustomer= (params) => instance.post("/admin/AiCall.Task/delCustomer",params);
//新增客户

export const  saveCustomer= (params) => instance.post("/admin/Crm.Customer/save",params);


//已呼客户详情

export const  getCallInfo= (params) => instance.post("/admin/AiCall.Task/getCallInfo",params);


//全部项目

export const  allProject= () => instance.post("/admin/AiCall.Project/all");

//外呼任务删除
export const  delTask= (params) => instance.post("/admin/AiCall.Task/delTask",params);




//分支问题列表

export const  problemList= (params) => instance.post("/admin/AiCall.CallGather/problemList",params);
//开/关问题列表


export const  updateProblemStatus= (params) => instance.post("/admin/AiCall.CallGather/updateProblemStatus",params);


//无应答列表

export const  callNoRespList= (params) => instance.post("/admin/AiCall.CallGather/callNoRespList",params);

//修改无应答

export const  updateCallNoResp= (params) => instance.post("/admin/AiCall.CallGather/updateCallNoResp",params);

//增，改分类设置


export const  saveCategory= (params) => instance.post("/admin/AiCall.CallGather/saveCategory",params);
//分类设置列表

export const  categoryList= (params) => instance.post("/admin/AiCall.CallGather/categoryList",params);
//删除分类设置

export const  delCategory= (params) => instance.post("/admin/AiCall.CallGather/delCategory",params);


//操作日志列表

export const  getLog= (params) => instance.post("/admin/AiCall.CallGather/getLog",params);

//根据项目获取线路

export const  getLine= (params) => instance.post("/admin/AiCall.Project/getLine",params);
//项目列表

export const  projectList= (params) => instance.post("/admin/AiCall.Project/list",params);
//业务类型

export const  getBusiness= (params) => instance.post("/admin/AiCall.Project/getBusiness",params);
//增改项目
export const  saveProject= (params) => instance.post("/admin/AiCall.Project/save",params);

//删除项目
export const  delProject= (params) => instance.post("/admin/AiCall.Project/del",params);
//呼叫记录列表

export const  callHistoryList= (params) => instance.post("/admin/AiCall.CallHistory/list",params);

export const  exportCallHistoryList= (params,configs) => instance.post("/admin/AiCall.CallHistory/list",params,configs);

//修改已呼客户详情

export const  updatePagedCustomer= (params) => instance.post("/admin/AiCall.Task/updateCustomer",params);


//语音验收
export const  acceptance= (params) => instance.post("/admin/AiCall.CallGather/acceptance",params);

//客户列表
export const  customerList= (params) => instance.post("/admin/Crm.Customer/list",params);

//新增修改客户
// export const  Savecustomer= (params) => instance.post("/admin/Crm.Customer/save",params);

//加入黑名单


export const  joinBlackGroup= (params) => instance.post("/admin/Crm.Customer/joinBlackGroup",params);
//客户标签组
export const  customerTagList= (params) => instance.post("/admin/Crm.CustomerTag/list",params);
//添加标签分组
export const  saveGroupTag= (params) => instance.post("/admin/Crm.CustomerTag/saveGroup",params);
//删除标签分组

export const  delGroupTag= (params) => instance.post("/admin/Crm.CustomerTag/delGroup",params);

//标签列表
export const  getTagList= (params) => instance.post("/admin/Crm.CustomerTag/getTagList",params);
//添加标签

export const  saveTag= (params) => instance.post("/admin/Crm.CustomerTag/saveTag",params);
//标签删除
export const  delTag= (params) => instance.post("/admin/Crm.CustomerTag/delTag",params);
//客户来源组

export const  customerSourceList= (params) => instance.post("/admin/Crm.CustomerSource/list",params);
//添加来源分组
export const  saveGroupSource= (params) => instance.post("/admin/Crm.CustomerSource/saveGroup",params);
//删除来源分组

export const  delGroupSource= (params) => instance.post("/admin/Crm.CustomerSource/delGroup",params);
//来源列表
export const  getSourceList= (params) => instance.post("/admin/Crm.CustomerSource/getTagList",params);
//添加来源

export const  sourceTag= (params) => instance.post("/admin/Crm.CustomerSource/saveTag",params);
//来源删除
export const  delSource= (params) => instance.post("/admin/Crm.CustomerSource/delTag",params);
//线索挖掘列表

export const  clueList= (params) => instance.post("/admin/Delve.Clue/list",params);

//领取线索
export const  getContactInfo= (params) => instance.post("/admin/Delve.Clue/getContactInfo",params);

//获取公司信息
export const  companyInfo= (params) => instance.post("/admin/Delve.Clue/info",params);

//获取我的线索

export const  adminClueList= (params) => instance.post("/admin/Delve.AdminClue/list",params);
//获取分配人员

export const  getAdminList= () => instance.post("/admin/System.Admin/getAdminList");

//释放线索


export const  release= () => instance.post("/admin/Delve.AdminClue/release");

//全部客户标签

export const  getAllTagList= () => instance.post("/admin/Crm.CustomerTag/getAllTagList");
//全部客户来源


export const  getAllSourceList= () => instance.post("/admin/Crm.CustomerSource/getAllSourceList");
//导入客户

export const  importCustomer= (params) => instance.post("/admin/Delve.AdminClue/importCustomer",params);

//获取账户管理顶部数据
export const  accountTop= () => instance.post("/admin/System.Account/top");
//获取账户管理充值记录

export const  rechargeList= (params) => instance.post("/admin/System.Account/rechargeList",params);

//获取全部任务列表

export const  allTask= () => instance.post("/admin/AiCall.Task/all");

//获取账户管理消费记录

export const  expenseList= (params) => instance.post("/admin/System.Account/expenseList",params);

//获取子账户组列表

export const  roleList= (params) => instance.post("/admin/System.Admin/groupList",params);

//获取全部权限
export const  allRule= () => instance.post("/admin/System.Admin/allRule");

//新增子账户组

export const  saveGroup= (params) => instance.post("/admin/System.Admin/saveGroup",params);

//添加成员
export const  saveAdmin= (params) => instance.post("/admin/System.Admin/saveAdmin",params);

//删除组
export const  delGroup= (params) => instance.post("/admin/System.Admin/delGroup",params);

//删除用户
export const  delAdmin= (params) => instance.post("/admin/System.Admin/delAdmin",params);

//用户重置密码

export const  resetPassword= (params) => instance.post("/admin/System.Admin/resetPassword",params);

//首页数据

export const  indexInfo= (params) => instance.post("/admin/Index/index",params);

//机器人列表

export const  robotList= () => instance.post("/admin/System.Robot/list");

//获取个人信息
export const  personInfo= () => instance.post("/admin/System.Info/index");

//修改昵称

export const  updateNickname= (params) => instance.post("/admin/System.Info/updateNickname",params);

//修改邮箱

export const  updateEmail= (params) => instance.post("/admin/System.Info/updateEmail",params);

//修改密码

export const  updatePassword= (params) => instance.post("/admin/System.Info/updatePassword",params);

//系统信息
export const  noticeList= (params) => instance.post("/admin/System.System/noticeList",params);

//操作日志
export const  logList= (params) => instance.post("/admin/System.Log/logList",params);

//给用户设置标签

export const  updateTag= (params) => instance.post("/admin/Crm.Customer/updateTag",params);
//给用户设置来源

export const  updateSource= (params) => instance.post("/admin/Crm.Customer/updateSource",params);

//模版下载
export const  getUploadTemplate= () => instance.post("/admin/Upload/getUploadTemplate");


//从客户导入
export const  taskImportCustomer= (params) => instance.post("/admin/AiCall.Task/importCustomer",params);

//黑名单批量导入

export const  importBlackList= (params) => instance.post("/admin/AiCall.BlackList/import",params);

//crm 客户呼叫记录

export const  getCrmCallInfo= (params) => instance.post("/admin/Crm.Customer/getCallInfo",params);

//黑名单导出

export const exportBlackCustomerList = (params,configs) => instance.post("/admin/AiCall.BlackList/getBlackCustomerList",params,configs);


//客户列表导出
export const  exportcustomerList= (params,configs) => instance.post("/admin/Crm.Customer/list",params,configs);

//客户列表导出
export const  sendSmsCode= (params) => instance.post("/admin/System.Info/sendSmsCode",params);

//修改手机号
export const  updatePhone= (params) => instance.post("/admin/System.Info/updatePhone",params);

//流程图数据
export const  flowList= (params) => instance.post("/admin/AiCall.CallGather/allToneBranch",params);

//分支问题语境

export const  questionBranchList= (params) => req.post("/api/gather/question_branch_list",params);

//新增/编辑分支问题语境

export const  addQuestionBranch= (params) => req.post("/api/gather/question_branch_edit",params);

//获取所有主线

export const  mainLineList= (params) => req.post("/api/gather/main_line_list",params);

//删除分支问题语境

export const  delQusBranch= (params) => req.post("/api/gather/question_branch_delete",params);

//线路管理列表

export const  routesList= (params) => req.get("/api/phone/b/routes",{params});

//首页线索数据

export const  homeInfo= () => req.get("/api/clue/home/b/info");

//首页实时数据

export const  realtimeData= (params) => req.get("/api/realtime-data/home/b/info",{params});


//添加/修改隐私号码
export const  xNumberEdit= (params) => req.post("/api/b/manual/conf/xNumberEdit",params);

//获取隐私号码列表
export const  xList= (params) => req.post("/api/b/manual/conf/xList",params);

//删除隐私号码

export const  xNumberDel= (params) => req.post("/api/b/manual/conf/xNumberDel",params);

//单个添加被叫号码
export const  xCallCreate= (params) => req.post("/api/b/manual/conf/xCallCreate",params);

//被叫列表
export const  xCallList= (params) => req.post("/api/b/manual/conf/xCallList",params);

//删除被叫列表
export const  xCallDel= (params) => req.post("/api/b/manual/conf/xCallDel",params);

//被叫模版下载
// export const  getUploadTemplate= () => req.post("/example/%B1%BB%BD%D0%B5%BC%C8%EB%C4%A3%B0%E5.xlsx");

//主叫账户添加
export const  xBindUser= (params) => req.post("/api/b/manual/conf/xBindUser",params);
//待绑定用户列表

export const  userList= () => req.post("/api/b/manual/conf/userList");



//获取当前用户绑定的隐私号信息
export const  xInfo= () => req.post("/api/b/manual/call/xInfo");

//拨打号码列表
export const  dialCallList= (params) => req.post("/api/b/manual/call/callList",params);
//修改评分
export const  changeIntentionality= (params) => req.post("/api/b/manual/call/changeIntentionality",params);
//修改备注
export const  changeRemark= (params) => req.post("/api/b/manual/call/changeRemark",params);

//绑定号码
export const  bindNumber= (params) => req.post("/api/b/manual/call/bindNumber",params);

//解绑号码

export const  unBindNumber= (params) => req.post("/api/b/manual/call/unBindNumber",params);

//号码审计列表
export const  auditList= (params) => req.post("/api/b/manual/audit/list",params);

//人工外呼消费记录表
export const  manualCallExpense= (params) => req.post("/api/b/conf/account/manualCallExpense",params);
